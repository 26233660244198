import { QueryClientProvider } from "@tanstack/react-query";
import { MotionConfig } from "framer-motion";
import { SnackbarProvider } from "notistack";
import type { ReactNode } from "react";
import React from "react";

import { reactQueryClient } from "@gdco/fe-core/fetch/QueryClient";

export default function RootLayout(props: RootLayoutProps) {
  return (
    <SnackbarProvider>
      <QueryClientProvider client={reactQueryClient}>
        <MotionConfig reducedMotion="user">
          <div className="antialiased bg-body text-body font-body scroll-smooth min-h-screen">
            {props.children}
          </div>
        </MotionConfig>
      </QueryClientProvider>
    </SnackbarProvider>
  );
}

type RootLayoutProps = {
  children: ReactNode;
};
