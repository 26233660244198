// set origin for frontend, leave empty for backend
const frontendOrigin = process.env.GATSBY_ENDPOINT_ORIGIN;

/**
 * Routes to the backend API
 *
 * @see https://github.com/GameDiscoverCo/shredder-api/blob/master/src/controller/route-external.js
 */

export const routeExternal = {
  // Frontend routes, to make life easier

  proPlatform: `${frontendOrigin}/pro/platforms/:platform`,
  // Timeframe: lifetime | monthly
  proDetailsTagExplorerTagidTimeframe: `${frontendOrigin}/pro/details/:masterid/tag-explorer/:tagid?timeframe=:timeframe`,

  // Routes pasted from https://github.com/GameDiscoverCo/shredder-api/blob/master/src/controller/route-external.js

  /* --- web: plus + focus domain --- */
  web: `${frontendOrigin}/web`,
  affinityExplorer: `${frontendOrigin}/web/affinity-explorer`,
  authenticate: `${frontendOrigin}/web/authenticate`,
  apiAccess: `${frontendOrigin}/web/api-access`,
  queryToken: `${frontendOrigin}/web/query-token`,
  changelog: `${frontendOrigin}/web/changelog`,
  chartDefaultDate: `${frontendOrigin}/web/chart-default-date`,
  browse: `${frontendOrigin}/web/browse/:status`,
  ccuForAppid: `${frontendOrigin}/web/ccu/:appid`,
  ccuRankings: `${frontendOrigin}/web/ccu-rankings`,
  contact: `${frontendOrigin}/web/contact`,
  countryList: `${frontendOrigin}/web/country-list`,
  countryDetail: `${frontendOrigin}/web/country-detail/:abbr`,
  dashboard: `${frontendOrigin}/web/dashboard`,
  epicTopSellers: `${frontendOrigin}/web/epic-top-sellers`,
  favoriteApps: `${frontendOrigin}/web/favorite-apps`,
  favoriteAppsDetails: `${frontendOrigin}/web/favorite-apps/details`,
  detailsRoot: `${frontendOrigin}/web/details/`,
  details: `${frontendOrigin}/web/details/:appid`,
  downloads: `${frontendOrigin}/web/downloads/:file`,
  followers: `${frontendOrigin}/web/followers`,
  heatmap: `${frontendOrigin}/web/heatmap`,
  hype: `${frontendOrigin}/web/hype`,
  hypeAnnotations: `${frontendOrigin}/web/hype-annotations`,
  hypeAnnotatorApps: `${frontendOrigin}/web/hype-annotator-apps`,
  hypeAnnotatorDetails: `${frontendOrigin}/web/hype-annotator-details/:masterid`,
  hypeConversionTagRanking: `${frontendOrigin}/web/hype-conversion-tag-ranking`,
  hypeConversionSignificantPostRelease: `${frontendOrigin}/web/hype-conversion-significant-post-release`,
  mostReviewed: `${frontendOrigin}/web/most-reviewed`,
  nocturne: `${frontendOrigin}/web/nocturne`,
  partnerList: `${frontendOrigin}/web/partners/:type`,
  partnerByName: `${frontendOrigin}/web/partner/:name/`,
  partnerHitList: `${frontendOrigin}/web/partner-hit-list`,
  reviewSummary: `${frontendOrigin}/web/review-summary/:appid`,
  postReleaseMonthly: `${frontendOrigin}/web/post-release-monthly`,
  postReleaseWeekly: `${frontendOrigin}/web/post-release-weekly`,
  monthlyAll: `${frontendOrigin}/web/monthly-all`,
  weeklyAll: `${frontendOrigin}/web/weekly-all`,
  saleEvents: `${frontendOrigin}/web/sale-events`,
  saleEventsDetails: `${frontendOrigin}/web/sale-events/:eventid`,
  salesExplorer: `${frontendOrigin}/web/sales-explorer/:appid`,
  search: `${frontendOrigin}/web/search`,
  studiosToWatch: `${frontendOrigin}/web/studios-to-watch`,
  tagExplorer: `${frontendOrigin}/web/tag-explorer`,
  tagExplorerTag: `${frontendOrigin}/web/tag-explorer/:tag`,
  tagRanking: `${frontendOrigin}/web/tag-ranking`,
  tagFilterOptions: `${frontendOrigin}/web/tag-filter-options`,
  trending: `${frontendOrigin}/web/trending/:variant`,
  trendingTagRanking: `${frontendOrigin}/web/trending-tag-ranking`,
  wishlists: `${frontendOrigin}/web/wishlists`,
  convertAppidToMasterid: `${frontendOrigin}/web/convert/appid-to-masterid/:appid`,
  convertMasteridToAppid: `${frontendOrigin}/web/convert/masterid-to-appid/:masterid`,
  compare: `${frontendOrigin}/web/compare`,
  forecast: `${frontendOrigin}/web/forecast`,
  /* --- web: root domain --- */
  newsletterPreview: `${frontendOrigin}/web/newsletter-preview`,
  /* --- pro: pro domain --- */
  proAffinityExplorer: `${frontendOrigin}/pro/affinity-explorer`,
  proBrowse: `${frontendOrigin}/pro/browse/:status`,
  proDauMauRankingsDau: `${frontendOrigin}/pro/dau-mau-rankings/dau`,
  proDauMauRankingsMau: `${frontendOrigin}/pro/dau-mau-rankings/mau`,
  proHype: `${frontendOrigin}/pro/hype`,
  proDetailsRoot: `${frontendOrigin}/pro/details/`,
  proDetails: `${frontendOrigin}/pro/details/:masterid`,
  proDetailsCharts: `${frontendOrigin}/pro/details/:masterid/charts`,
  proDetailsTagExplorer: `${frontendOrigin}/pro/details/:masterid/tag-explorer`,
  proDetailsTagExplorerTagid: `${frontendOrigin}/pro/details/:masterid/tag-explorer/:tagid`,
  proPlatformsAll: `${frontendOrigin}/pro/platforms/all`,
  proPlatformsSteam: `${frontendOrigin}/pro/platforms/steam`,
  proPlatformsXbox: `${frontendOrigin}/pro/platforms/xbox`,
  proPlatformsPlaystation: `${frontendOrigin}/pro/platforms/playstation`,
  proPublisherDeveloper: `${frontendOrigin}/pro/publisher-developer`,
  proPublisherDeveloperPartnerid: `${frontendOrigin}/pro/publisher-developer/:partnerid`,
  proSearch: `${frontendOrigin}/pro/search`,
  proTagExplorer: `${frontendOrigin}/pro/tag-explorer`,
  proTagExplorerTag: `${frontendOrigin}/pro/tag-explorer/:tag`,
  proTagRanking: `${frontendOrigin}/pro/tag-ranking`,
  proCountryListPlaystation: `${frontendOrigin}/pro/country-list/playstation`,
  proCountryListXbox: `${frontendOrigin}/pro/country-list/xbox/:type`,
  proCountryDetail: `${frontendOrigin}/pro/country-detail/:platform/:abbr`,
  /* --- GraphQL --- */
  graphql: `${frontendOrigin}/graphql`,
  /* --- plus API --- */
  plus: `${frontendOrigin}/plus`,
  plusDetails: `${frontendOrigin}/plus/details/:appid`,
  plusFollowers: `${frontendOrigin}/plus/followers`,
  plusHype: `${frontendOrigin}/plus/hype`,
  plusPostRelease: `${frontendOrigin}/plus/post-release`,
  plusWishlists: `${frontendOrigin}/plus/wishlists`,
  /* --- site admin --- */
  adminApiToken: `${frontendOrigin}/admin/api-token`,
  adminDataRequest: `${frontendOrigin}/admin/data-request`,
  adminDev: `${frontendOrigin}/admin/dev`,
  adminRecalculateCharts: `${frontendOrigin}/admin/recalculate-charts`,
  adminUsers: `${frontendOrigin}/admin/users`,
  adminUser: `${frontendOrigin}/admin/users/:userid`,
};
