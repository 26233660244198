/* eslint-disable @nx/enforce-module-boundaries -- no aliases in config files */

/** @see https://www.gatsbyjs.org/docs/browser-apis/ */

import type { GatsbyBrowser } from "gatsby";
import React from "react";

import RootLayout from "./src/components/layout/RootLayout";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";

import "./src/assets/styles/global.css";

export * from "../../libs/fe-config/src/gatsby-browser.base";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => <RootLayout>{element}</RootLayout>;

/* eslint-enable @nx/enforce-module-boundaries */
